import React from "react";

import { Container, ServicesDetail, SEOPage } from "../components";

import { StyledPage } from "../components/css";

export default function ServiceDetail() {

  //On page SEO
  const SEO = {
    entity: "page",
    title: "Services",
    keywords: `Property and facilities management,Accounting and reporting,Marketing,Asset management,Lease negotiation`,
    description: `No service is out of our skill set.`,
    url: `/services`,
  }

  return (
    <Container page="content" breadcrumbs={true} breadData={{ type: "content", title: SEO.title }}>

      {/* Page meta data (title, description, etc.)*/}
      <SEOPage
        entity={SEO.entity}
        title={SEO.title}
        keywords={SEO.keywords}
        description={SEO.description}
        url={SEO.url}
      />

      <StyledPage>

        <ServicesDetail />

      </StyledPage>

    </Container>
  )
}